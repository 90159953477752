.home {
  position: absolute;
  top: 100px;
  margin: 0px 102px;
  width: calc(100vw - 205px);
  height: calc(100vh - 110px);
  background: transparent linear-gradient(90deg, #f2f2f2 0%, #ffffff00 100%) 0%
    0% no-repeat padding-box;
  background-color: transparent;
  opacity: 1;
}
.apple {
  position: absolute;
  top: 35%;
  left: 50%;
  text-align: center;
  width: 240px;
  height: 280px;
  margin: -90px 0 0 -125px;
  opacity: 1;
  z-index: -1;
}
.welcome-title-container {
  width: 100%;
  text-align: center;
}
.welcome-title {
  margin-top: calc(50vh - 200px);
  font-family: poppins;
  font-size: 79px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.products-title {
  margin-top: 10%;
  color: #5ac8fa;
  font-family: poppins;
  font-size: 20px;
  letter-spacing: 0px;
  opacity: 1;
}
.icons {
  margin-top: 5%;
  text-align: center;
  display: flex;
  justify-content: center;
}
.iphone-icon {
  width: 22px;
  height: 40px;
  margin-left: 10px;
}
.mac-icon {
  margin-top: 13px;
  margin-left: 50px;
  width: 37px;
  height: 25px;
}
.watch-icon {
  margin-top: 4px;
  margin-left: 50px;
  width: 25px;
  height: 35px;
}
