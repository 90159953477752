.vertical-icons {
  position: absolute;
  right: 0;
  margin-right: 30px;
  margin-top: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 15;
}
.iphone-icon-vertical {
  margin-top: -20px;
  width: 40px;
  height: 40px;
}
.mac-icon-vertical {
  margin-top: 15px;
  width: 32px;
  height: 38px;
}
.watch-icon-vertical {
  margin-top: 20px;
  width: 25px;
  height: 30px;
}
