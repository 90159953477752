.container {
  margin-left: 102px;
}
.macbook-title-part {
  position: relative;
  width: calc(100vw - 102px);
  height: 55vh;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
}
.macbook-title {
  position: absolute;
  margin-top: 7vh;
  margin-left: 3%;
  font-family: poppins;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #5ac8fa;
  opacity: 1;
}
.macbook-title-sub {
  position: absolute;
  margin-top: 15vh;
  margin-left: 3%;
  margin-bottom: 0px;
  width: 632px;
  height: 250px;
  font-family: poppins;
  font-size: 100px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  line-height: 100px;
}
.macbook-specs {
  margin-top: 37vh;
  margin-bottom: 0;
  margin-left: 3%;
  font-family: poppins;
  font-size: 50px;
  font-weight: bold;
  width: 190px;
  height: 56px;
  text-align: left;
  letter-spacing: 0px;
  color: #cccccc;
  opacity: 0.44;
}
.macbook-specs-description {
  margin-left: 3%;
  font-family: poppins;
  font-size: 20px;
  font-weight: bold;
  width: 190px;
  height: 40px;
  text-align: left;
  letter-spacing: 0px;
  color: #cccccc;
  opacity: 0.44;
}
.macbook-pro-image {
  position: absolute;
  top: 0px;
  z-index: 15;
  margin-left: 40vw;
  width: 50vw;
  opacity: 1;
  height: 55vh;
  opacity: 0.64;
}
.bottom-part-mac {
  position: relative;
  width: calc(100vw - 110px);
  height: calc(45vh - 120px);
}
.inline {
  display: inline-flex;
}
.buy-now-mac-container {
  min-width: 200px;
  margin-left: 30%;
  margin-top: 50%;
  height: 100%;
}
.buy-now-mac {
  padding-left: 20%;
  font-family: poppins;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #5ac8fa;
  opacity: 1;
}
.apple-products {
  position: absolute;
  right: 35vw;
  margin-top: 1%;
  margin-left: 10vw;
  height: 94%;
}
.apple-products-image {
  height: 100%;
  width: 30vw;
  opacity: 1;
}
.subscription-form {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  margin-left: 15px;
  margin-top: -4vh;
  padding: 50px 0px;
  width: 35vw;
  height: 25vh;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  opacity: 1;
  z-index: 25;
}
.subscribe-now {
  position: absolute;
  margin-top: -100px;
  margin-left: -150px;
  width: 374px;
  height: 51px;
  font-family: poppins;
  font-size: 36px;
  font-weight: bold;
}
.subscription-email-input {
  position: absolute;
  margin-top: 50px;
  margin-left: -50px;
  padding-left: 15px;
  width: 350px;
  height: 54px;
  font-family: poppins;
  font-size: 20px;
  font-weight: 700;
  border: none;
}
.subscription-button {
  position: absolute;
  margin-top: 50px;
  margin-left: 250px;
  width: 154px;
  height: 54px;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
  color: white;
  border: none;
}
