.signup-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  position: absolute;
  width: 480px;
  height: 480px;
  top: 50%;
  left: 50%;
  margin: -240px 0 0 -240px;
  background: #ffffff;
}
.signup-form {
  padding: 2rem;
}
.signup-title {
  margin-top: 0px;
  /* margin-bottom: 0px; */
  text-align: center;
  font-family: poppins;
  font-weight: 900;
}

.sign-up-field-labels {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-family: poppins;
  font-weight: 500;
}
#name,
#email,
#password {
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  outline: none;
  border: 1px lightgrey solid;
  border-radius: 3px;
  margin-bottom: 10px;
  font-family: poppins;
}
.sign-up-btn {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 0.3rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0.7rem 3rem;
  background-color: black;
  color: white;
  outline: none;
  border: none;
  border-radius: 3px;
  font-family: poppins;
  font-size: 12px;
  font-weight: 500;
}
.sign-in-for-sign-up {
  position: absolute;
  top: 92%;
  left: 32%;
  margin-top: 55px;
  font-size: 12px;
  font-family: poppins;
  font-weight: bolder;
}
.sign-in-link {
  text-decoration: none;
}
