.pre-nav {
  text-align: center;
  top: 0px;
  left: 0px;
  height: 100px;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
}
.pre-nav-logo {
  position: absolute;
  top: 32px;
  width: 32px;
  height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.pre-home-page {
  margin-top: 7px;
  background: #ffffff;
  margin-left: 107px;
  margin-right: 107px;
  height: calc(100vh - 107px);
  background: transparent linear-gradient(180deg, #5ac8fa 0%, #ffffff00 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}
.pre-apple {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 370px;
  margin: -170px 0 0 -200px;
  opacity: 1;
}
.counter {
  position: absolute;
  top: 85%;
  left: 50%;
  width: 250px;
  margin: 0 0 0 -110px;
}
.year {
  height: 80px;
  margin-left: -25px;
  opacity: 1;
  font-family: poppins;
  font-size: 50px;
  font-weight: 900;
}
.first-digit {
  margin-left: 30px;
}
.second-digit {
  margin-left: 30px;
}

.third-digit {
  margin-left: 30px;
}
.fourth-digit {
  margin-left: 30px;
}
