.login-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  position: absolute;
  width: 480px;
  height: 420px;
  top: 50%;
  left: 50%;
  margin: -210px 0 0 -240px;
  background: #ffffff;
}
.login-form {
  padding: 2rem;
}
.title {
  margin-top: 10px;
  margin-bottom: 0px;
  text-align: center;
  font-family: poppins;
  font-weight: 900;
}
.field-label {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-family: poppins;
  font-weight: 500;
}
#email,
#password {
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  outline: none;
  border: 1px lightgrey solid;
  border-radius: 3px;
  margin-bottom: 10px;
  font-family: poppins;
}
.sign-in {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 1.3rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0.7rem 3rem;
  background-color: black;
  color: white;
  outline: none;
  border: none;
  border-radius: 3px;
  font-family: poppins;
  font-size: 12px;
  font-weight: 500;
}
.sign-up {
  position: absolute;
  top: 92%;
  left: 35%;
  margin-top: 50px;
  font-size: 12px;
  font-family: poppins;
  font-weight: bolder;
}
.sign-up-link {
  text-decoration: none;
}
.error-top-container {
  text-align: center;
  min-height: 30px;
}
.error-top {
  color: red;
  margin-top: -20px;
  font-family: poppins;
  font-weight: bold;
  font-size: 20px;
}
.error-container {
  min-height: 30px;
}
.error {
  color: red;
  margin-top: -20px;
  font-family: poppins;
  font-weight: 400;
  font-size: 12px;
}
